import { PrismicRichText } from '@prismicio/react';
import { GatsbyLinkProps, Link as GastbyLink } from 'gatsby';
import React from 'react';
import styled, { css } from 'styled-components';

export interface LinkProps extends GatsbyLinkProps<unknown> {
  as?: React.ElementType;
  size?: 'sm' | 'md' | 'lg';
  href?: string;
  opacity?: 'light' | 'full' | 'inherit';
}

// Add to these local routes as needed
const localRoutes = [
  'https://cloudkitchens.com',
  'http://localhost:8000',
  'http://localhost:9000',
  'https://_',
  'http://_',
];

/** Strips initial part of URL to make a relative path, if the host is in a list of known local hosts */
export function relativeLinkResolver(url?: string) {
  if (!url) return '/';
  if (['?', '/', '#'].includes(url[0])) return url;

  for (const route of localRoutes) {
    if (url.includes(route)) return url.replace(route, '');
  }

  return url;
}

export const getLinkProps = (props) => ({
  ...props.link,
  to: props.link?.url,
  color: props.link_color,
  children:
    props.children ?? props.link_text?.richText ? (
      <PrismicRichText field={props.link_text.richText} />
    ) : (
      props.link_text
    ),
});

const getSize = ({ size }: LinkProps) => {
  switch (size) {
    case 'lg':
      return css`
        font-size: 2rem;
        line-height: 1;
      `;

    case 'sm':
    case 'md':
      return css`
        font-size: 1.1rem;
        line-height: 1.2;
      `;

    default:
      return '';
  }
};

const getOpacity = ({ opacity }: LinkProps) => {
  switch (opacity) {
    case 'light':
      return 0.7;
    case 'full':
      return 1.0;
    case 'inherit':
      return 'inherit';
    default:
      return 0.7;
  }
};

const getHoverOpacity = ({ opacity }: LinkProps) => {
  switch (opacity) {
    case 'light':
      return 1;
    case 'full':
      return 0.7;
    case 'inherit':
      return 'inherit';
    default:
      return 1;
  }
};

const BaseLink = styled(GastbyLink)<LinkProps>`
  font-weight: 500;
  font-style: normal;

  // Size
  ${getSize}

  opacity: ${getOpacity};

  &:hover {
    opacity: ${getHoverOpacity};
  }
`;

export const Link: React.FC<LinkProps> = ({ to, ...props }) => {
  const href = relativeLinkResolver(to || props.href);

  return href && href[0] === '/' ? (
    <BaseLink to={href} {...props} />
  ) : (
    <BaseLink as="a" href={href} {...props} />
  );
};
