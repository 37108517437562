import * as CSDS from '@csscompany/csds-components';
import { asText } from '@prismicio/helpers';
import { hasRichText } from '@publicss/utils';
import classNames from 'classnames';
import { graphql } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import React from 'react';

import {
  Maybe,
  Button as PrismicButton,
  Scalars,
  _Linkable,
} from '../../../types/prismic';
import { usePageContext } from '../../providers/PageContext';
import { TypeformButton } from './TypeformButton';

export const commonButtonClasses =
  '[&_*]:!p-0 whitespace-nowrap rounded-full uppercase leading-none';

export const buttonSizeClasses = {
  sm: `py-2 px-[20px] text-[10px] font-semibold tracking-wider ${commonButtonClasses}`,
  md: `py-3 px-5 ${commonButtonClasses}`,
  lg: `py-3 px-9 ${commonButtonClasses}`,
};

const isInternalLink = (url: string) => url[0] === '/';

export type ButtonProps = Omit<CSDS.ButtonProps, 'size' | 'href'> & {
  color?: string;
  size?: 'sm' | 'md' | 'lg';
  buttonDoc?: any;
  href?: Maybe<string> | null;
  globalTypeform?: boolean;
  navCTAData?: {
    ctaLabel?: Maybe<Scalars['Json']> | null;
    ctaType?: Maybe<Scalars['String']> | null;
    ctaLink?: Maybe<_Linkable> | null;
    ctaFormID?: Maybe<string> | null;
  };
  [x: string]: any; // Suppress TS error when passing props through via spread operator (delegated)
};

/**
 * Button variant styles are configured via CSDS theming
 */
export const Button: React.FunctionComponent<ButtonProps> = ({
  children,
  href,
  className = '',
  variant = 'primary',
  emphasis = 'high',
  size = 'md',
  buttonDoc,
  globalTypeform = false,
  navCTAData,
  ...delegated
}) => {
  const {
    siteTag,
    global: { typeform_id },
  } = usePageContext();
  const isCK = siteTag === 'site:cloudkitchens';

  if (!children) return null;

  const isHackyEntitiesButton = !isCK && '#' === href?.split('/').pop();

  if (
    (globalTypeform || isHackyEntitiesButton) &&
    typeform_id &&
    navCTAData?.ctaType !== 'link'
  ) {
    //const richTextExists = navCTAData?.ctaLabel?.richText?.length > 0;
    const richTextContent: React.ReactNode = hasRichText(
      navCTAData?.ctaLabel?.richText
    )
      ? asText(navCTAData?.ctaLabel.richText)
      : children;

    return (
      <TypeformButton
        typeform_id={navCTAData?.ctaFormID || typeform_id}
        id={typeform_id}
        variant={variant}
        size={size}
        className={className}
        emphasis={emphasis}
        {...delegated}>
        {richTextContent}
      </TypeformButton>
    );
  }

  if (
    (globalTypeform || isHackyEntitiesButton) &&
    typeform_id &&
    navCTAData?.ctaType === 'link'
  ) {
    //const richTextExists = navCTAData?.ctaLabel?.richText?.length > 0;
    const richTextContent: React.ReactNode = hasRichText(
      navCTAData?.ctaLabel?.richText
    )
      ? asText(navCTAData?.ctaLabel.richText)
      : children;

    return (
      <CSDS.Button
        variant={variant}
        emphasis={emphasis}
        href={navCTAData?.ctaLink?.url}
        {...delegated}>
        {richTextContent}
      </CSDS.Button>
    );
  }

  const resolvedClassName = classNames(
    buttonSizeClasses[size],
    {
      'text-[#525252] hover:text-[#525252]': variant === 'secondary', // Fix for unconfigurable text color in CSDS theming
      typeform_cta: isCK,
    },
    className
  );

  // Only TypeformButton has fallback button content
  if (!globalTypeform && !children) return null;

  // Handle backwards compatibility for entities buttons that previously opened a dropdown
  if (!isCK && !buttonDoc?.document) {
    // TypeformButton has fallback hardcoded text of 'BOOK NOW' in CK
    // otherwise I would disallow buttons without children altogether
    if (!children) return null;

    // We want to support buttons that used the /# href to open the entities modal
    // ...as well as buttons that should be typeform but typeform hasn't been
    // configured for that site yet
    if (isHackyEntitiesButton || (globalTypeform && !typeform_id && !href)) {
      const handleClick = () => {
        const entitiesModalButton = document.querySelector<HTMLButtonElement>(
          '#entities-modal-button'
        );
        if (entitiesModalButton) entitiesModalButton?.click();
      };
      return (
        <CSDS.Button
          className={resolvedClassName}
          variant={variant}
          emphasis={emphasis}
          onClick={handleClick}
          {...delegated}>
          {children}
        </CSDS.Button>
      );
    }
  }

  /** PN: We should incrementally adopt using a Button Document type going forward.
   * Query the button document in the Slice Component and pass it in as a prop,
   * The button doc data will take precedence over any other button data passed */
  if (buttonDoc && buttonDoc?.document?.data) {
    const {
      button_text,
      typeform_id,
      external_link_url,
      internal_link_path,
      variant: buttonDocVariant,
    } = buttonDoc.document.data as PrismicButton;
    if (
      buttonDocVariant === 'Internal Link' &&
      internal_link_path &&
      button_text
    ) {
      return (
        <AniLink
          to={internal_link_path}
          cover
          direction="up"
          duration={1}
          bg="color--grey-3">
          <CSDS.Button
            className={resolvedClassName}
            variant={variant}
            emphasis={emphasis}
            {...delegated}>
            {button_text}
          </CSDS.Button>
        </AniLink>
      );
    }
    if (
      buttonDocVariant === 'External Link' &&
      external_link_url?.url &&
      button_text
    ) {
      return (
        <CSDS.Button
          className={resolvedClassName}
          href={external_link_url?.url}
          variant={variant}
          emphasis={emphasis}
          {...delegated}>
          {button_text}
        </CSDS.Button>
      );
    }
    if (buttonDocVariant === 'Typeform' && typeform_id && button_text) {
      return (
        <TypeformButton
          typeform_id={typeform_id}
          id={typeform_id}
          variant={variant}
          size={size}
          className={className}
          emphasis={emphasis}
          {...delegated}>
          {children}
        </TypeformButton>
      );
    }
  }

  return href ? (
    isInternalLink(href) ? (
      <AniLink to={href} cover direction="up" duration={1} bg="color--grey-3">
        <CSDS.Button
          className={resolvedClassName}
          variant={variant}
          emphasis={emphasis}
          {...delegated}>
          {children}
        </CSDS.Button>
      </AniLink>
    ) : (
      <CSDS.Button
        className={resolvedClassName}
        href={href}
        variant={variant}
        emphasis={emphasis}
        {...delegated}>
        {children}
      </CSDS.Button>
    )
  ) : (
    <CSDS.Button
      className={resolvedClassName}
      variant={variant}
      emphasis={emphasis}
      {...delegated}>
      {children}
    </CSDS.Button>
  );
};

export const query = graphql`
  fragment ButtonDocData on PrismicButton {
    data {
      variant
      button_text
      typeform_id
      internal_link_path
      external_link_url {
        url
      }
      video {
        url
      }
    }
  }
`;
