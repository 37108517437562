import { asText } from '@prismicio/helpers';
import { PrismicRichText } from '@prismicio/react';
import { isBrowser } from '@publicss/utils';
import classNames from 'classnames';
import { Link } from 'gatsby';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import { gsap } from 'gsap';
import React from 'react';
import styled, { css } from 'styled-components';

import FormResolver from '../components/forms/FormResolver';
import { useSiteLang } from '../hooks/useCKReducer';
import { useUTMCookies } from '../hooks/useUTMCookies';
import { media } from '../styles/media';
import { useIsMobile } from '../utils/breakpoints';
import {
  isFacilityDetailsPage,
  isAnyFacilityPage,
} from '../utils/kitchen-growth-helpers';
import LanguagePicker, { fmtLangKey } from './LanguagePicker';
import { Button } from './ui';

const Nav = (props) => {
  const [navModalOpen, setNavModalOpen] = React.useState(false);
  const [entitiesModalOpen, setEntitiesModalOpen] = React.useState(false); // To support regional site migration to CK
  const [showBookTour, setShowBookTour] = React.useState(false);
  const isMobile = useIsMobile();
  const utms = useUTMCookies();
  const closeNavModal = React.useCallback(() => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector<HTMLElement>('.wrapper');
    const modalBg = document.querySelector<HTMLElement>('.modal--bg');
    const htmlActive = document.querySelector<HTMLElement>(
      'html.is-scroll-disabled-nav'
    );
    if (htmlActive) {
      setNavModalOpen(false);
      setEntitiesModalOpen(false);
      modalBg?.classList.remove('is-active');
      html?.classList.remove('is-scroll-disabled-nav');
      const scrollY = wrapper?.style.top;
      if (wrapper) wrapper.style.top = '';
      window.scrollTo({
        top: parseInt(scrollY || '0') * -1,
        behavior: 'instant',
      });
    }
  }, [setNavModalOpen, setEntitiesModalOpen]);

  React.useEffect(() => {
    const escOutOfModal = (e) => {
      if (e.key === 'Escape') {
        closeNavModal();
      }
    };
    document.addEventListener('keydown', escOutOfModal);
    return () => {
      document.removeEventListener('keydown', escOutOfModal);
    };
  }, []);

  const modalOpen = navModalOpen || entitiesModalOpen;

  let animationNav: gsap.core.Timeline;

  React.useEffect(() => {
    if (isBrowser()) {
      sessionStorage.setItem('url', window.location.href);
    }

    const navElements = gsap.timeline();
    navElements
      .from('.nav--large li', {
        duration: 0.7,
        opacity: '0',
        delay: 0.4,
        y: '-7',
        stagger: {
          amount: 0.5,
        },
      })
      .from(
        '.fade-1',
        {
          opacity: '0',
          y: '-7',
          duration: 0.3,
          delay: 0.4,
        },
        0
      );

    animationNav = navElements;

    const onScrollCallback = () => {
      const OFFSET_Y = 256;
      const scrollY = window.scrollY;
      if (scrollY <= OFFSET_Y) {
        setShowBookTour(false);
      }

      if (scrollY >= OFFSET_Y) {
        setShowBookTour(true);
      }
    };
    window.addEventListener('scroll', onScrollCallback);
    return () => {
      window.removeEventListener('scroll', onScrollCallback);
    };
  }, [setShowBookTour]);

  const toggleModal = (modal: 'nav' | 'entities') => () => {
    const html = document.querySelector('html');
    const wrapper = document.querySelector<HTMLElement>('.wrapper');
    const modalBg = document.querySelector<HTMLElement>('.modal--bg');
    const htmlActive = document.querySelector<HTMLElement>(
      'html.is-scroll-disabled-nav'
    );
    if (htmlActive) {
      setNavModalOpen(false);
      setEntitiesModalOpen(false);
      modalBg?.classList.remove('is-active');
      html?.classList.remove('is-scroll-disabled-nav');
      const scrollY = wrapper?.style.top;
      if (wrapper) wrapper.style.top = '';
      window.scrollTo({
        top: parseInt(scrollY || '0') * -1,
        behavior: 'instant',
      });
    } else {
      modal === 'nav' ? setNavModalOpen(true) : setEntitiesModalOpen(true);
      animationNav && animationNav.restart().play();
      modalBg?.classList.add('is-active');
      setTimeout(function () {
        if (wrapper)
          wrapper.style.top = `-${document.documentElement.scrollTop}px`;
        html?.classList.add('is-scroll-disabled-nav');
      }, 500);
    }
  };

  const toggleNavModal = toggleModal('nav');
  const toggleEntitiesModal = toggleModal('entities');

  const {
    pageContext: {
      siteLangs,
      siteTag,
      primarySiteLang,
      pageType,
      moduleData: { navData, footerData },
      navCTAData,
      global,
    },
  } = props;

  const { siteLang } = useSiteLang();
  const primaryLang = primarySiteLang;

  const roofHrefNoPrefix = [
    'site:cloudkitchens',
    'site:cocinas-ocultas',
    'site:chefcollective',
    'site:kitchen-central',
    'site:kitchenbase',
    'site:kitchennow',
    'site:kitchen-valley',
    'site:profood-solutions',
    'site:pedidoslab',
    'site:smartcity-kitchens',
    'site:virtual-kitchens',
  ].includes(siteTag);

  const rootHref =
    !siteLang || roofHrefNoPrefix
      ? '/'
      : siteLang !== primaryLang
        ? `/${fmtLangKey(siteLang, siteLangs)}/`
        : '/';

  const isCK = siteTag === 'site:cloudkitchens';
  const isProFoodSolutions = siteTag === 'site:profood-solutions';
  const leftSideOfFormExists =
    !!global?.entities_modal_heading.richText.length &&
    !!global?.entities_modal_paragraph.richText.length;

  const {
    button,
    menu_description,
    menu_heading,
    top_links_alignment,
    top_link_text_1,
    top_link_url_1,
    top_links_1,
    top_link_text_2,
    top_links_2,
    top_link_url_2,
    top_link_text_3,
    top_link_url_3,
    top_links_3,
    top_link_text_4,
    top_link_url_4,
    top_links_4,
    top_link_text_5,
    top_link_url_5,
    top_links_5,
    dropdown_links,
  } = navData;
  const topLinksAlignment = top_links_alignment || 'center';

  const copyright_text =
    footerData?.prismicFooter?.copyright_text ?? 'All Rights Reserved.';

  if (pageType === 'landing_page') {
    return (
      <div className="nav--holder bg--grey-2">
        <NavStyles className="container">
          <div className="col-span-2 md:col-span-2 lg:col-span-4">
            <img
              src={global.text_logo?.url}
              alt={global.text_logo?.alt}
              className="db max-h-[24px]"
            />
          </div>
        </NavStyles>
      </div>
    );
  }

  const topLinks = [
    {
      link_text: top_link_text_1,
      link_url: top_link_url_1,
      links: top_links_1,
    },
    {
      link_text: top_link_text_2,
      link_url: top_link_url_2,
      links: top_links_2,
    },
    {
      link_text: top_link_text_3,
      link_url: top_link_url_3,
      links: top_links_3,
    },
    {
      link_text: top_link_text_4,
      link_url: top_link_url_4,
      links: top_links_4,
    },
    {
      link_text: top_link_text_5,
      link_url: top_link_url_5,
      links: top_links_5,
    },
  ];

  return (
    <>
      <NavStylesOuter
        siteTag={siteTag}
        className={classNames('nav--holder bg--grey-2', {
          'is-active': modalOpen,
        })}>
        <NavStyles
          className={classNames(
            'grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12',
            isFacilityDetailsPage()
              ? 'xlg:px-0 m-auto !max-w-[1376px] px-[20px] md:px-[32px]'
              : isAnyFacilityPage()
                ? 'px-[32px] lg:px-[20px]'
                : 'container'
          )}>
          <div
            className={classNames('col-span-2 md:col-span-2 lg:col-span-4', {
              'max-h-[32px]': isProFoodSolutions,
            })}>
            {isFacilityDetailsPage() &&
            isMobile &&
            !props.url.includes('gallery') ? (
              <div className="flex items-center gap-4">
                <AniLink
                  id="ck-logo"
                  aria-label="Go to homepage"
                  to={rootHref}
                  cover
                  direction="up"
                  duration={1}
                  className="inline-block w-fit"
                  style={{
                    animation: `${showBookTour ? 'none' : 'fade-in .25s'}`,
                    display: `${showBookTour ? 'none' : 'block'}`,
                  }}
                  bg="color--grey-3">
                  <img
                    src={global.text_logo?.url}
                    alt={global.text_logo?.alt ?? 'logo'}
                    className={classNames('db', {
                      'max-h-[24px]': !isProFoodSolutions,
                      'max-h-[32px]': isProFoodSolutions,
                    })}
                  />
                </AniLink>
                <Button
                  globalTypeform
                  utm_content={utms.utm_content || 'sticky-header-cta'}
                  id="book-tour-nav-button"
                  size="sm"
                  className="ff_cta header_cta"
                  style={{
                    animation: `${showBookTour ? 'fade-in .25s' : 'none'}`,
                    display: `${showBookTour ? 'block' : 'none'}`,
                  }}>
                  {asText(button.richText)}
                </Button>
              </div>
            ) : (
              <AniLink
                to={rootHref}
                onClick={() => {
                  if (entitiesModalOpen || navModalOpen) closeNavModal();
                }}
                aria-label="Go to homepage"
                cover
                direction="up"
                duration={1}
                className="inline-block w-fit"
                bg="color--grey-3">
                <img
                  src={global.text_logo?.url}
                  alt={global.text_logo?.alt ?? 'logo'}
                  className={classNames('db', {
                    'max-h-[24px]': !isProFoodSolutions,
                    'max-h-[32px]': isProFoodSolutions,
                  })}
                />
              </AniLink>
            )}
          </div>
          {topLinksAlignment === 'center' && (
            <TopLinks topLinks={topLinks} alignment={topLinksAlignment} />
          )}

          <ul
            className={classNames('nav--cta col-span-2 flex lg:col-span-4', {
              'md:col-span-6 lg:col-span-8': topLinksAlignment === 'end',
            })}>
            {topLinksAlignment === 'end' && (
              <TopLinks topLinks={topLinks} alignment={topLinksAlignment} />
            )}
            <li className="nav--hide">
              <ContactLink global={global} />
            </li>
            {isProFoodSolutions ? (
              <li
                className={classNames('nav--hide flex', {
                  'md-down:hidden': siteTag !== 'site:profood-solutions',
                })}>
                {props.url === '/terms/' ||
                props.url === '/privacy-policy/' ||
                props.url === '/cookies-policy/' ? (
                  <Button
                    size="sm"
                    globalTypeform
                    buttonClassName="header_cta !py-2"
                    utm_content="sticky-header-cta"
                    navCTAData={navCTAData}>
                    {asText(button.richText)}
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    globalTypeform
                    buttonClassName="header_cta !py-2"
                    utm_content="sticky-header-cta"
                    onClick={(event) => {
                      event.preventDefault();
                      const anchorElement = document.querySelector('#contact');
                      console.log('click');
                      if (anchorElement) {
                        anchorElement.scrollIntoView();
                      }
                    }}>
                    {asText(button.richText)}
                  </Button>
                )}
              </li>
            ) : (
              <li
                className={classNames('nav--hide flex', {
                  'md-down:hidden': siteTag !== 'site:profood-solutions',
                })}>
                {props.url === '/careers' || props.url === '/careers/' ? (
                  <Button
                    href="/commercial-kitchens/"
                    emphasis="medium"
                    variant="secondary"
                    size="sm">
                    {asText(button.richText)}
                  </Button>
                ) : (
                  <Button
                    size="sm"
                    globalTypeform
                    buttonClassName="header_cta !py-2"
                    utm_content="sticky-header-cta"
                    navCTAData={navCTAData}>
                    {asText(button.richText)}
                  </Button>
                )}
              </li>
            )}
            <li>
              <LanguagePicker />
            </li>
            <li className="nav--icon--menu">
              <button
                onClick={toggleNavModal}
                className={`${
                  modalOpen ? 'is-active' : ''
                } bg-[transparent] sm-down:translate-x-1 sm-down:p-2`}>
                <svg
                  version="1.1"
                  viewBox="0 0 12 11"
                  xmlns="http://www.w3.org/2000/svg"
                  x="0"
                  y="0"
                  className="icon--menu rtl:scale-x-[-1]">
                  <rect
                    x="0"
                    y="4.1"
                    className="line-1"
                    width="12"
                    height="1.6"
                  />
                  <rect
                    x="4"
                    y="8.4"
                    className="line-2"
                    width="8"
                    height="1.6"
                  />
                  <rect x="4" y="0" className="line-3" width="8" height="1.6" />
                </svg>
              </button>
            </li>
            {!isCK && (
              <li>
                <button
                  id="entities-modal-button"
                  tabIndex={-1}
                  aria-hidden="true"
                  className="sr-only"
                  onClick={toggleEntitiesModal}
                />
              </li>
            )}
          </ul>
        </NavStyles>
      </NavStylesOuter>
      <div>
        <NavDropdown
          siteTag={siteTag}
          className={classNames('bg--grey-2 pt-20 md:pb-16 md:pt-24 lg:pb-24', {
            'is-active': navModalOpen,
          })}>
          <div className="container grid grid-cols-1 items-end md:grid-cols-8 lg:grid-cols-12">
            <div className="fade-1 height-margin order-2 col-span-8 hidden md:mt-20 md:block lg:order-1 lg:col-span-7 lg:mt-0">
              <p className="eyebrow color--grey-6 hide-height mb-6">
                {asText(menu_heading.richText)}
              </p>
              <div className="w-27 small color--grey-5 hide-height text-sm">
                <PrismicRichText field={menu_description.richText} />
              </div>
              <div className="nav--contact mt-12 flex gap-4">
                {global?.contact_phone && global?.contact_phone_label ? (
                  <div>
                    <p className="color--grey-6 md mb-0">
                      {global?.contact_phone_label}
                    </p>
                    <p className="small">
                      <a
                        dir="auto"
                        href={`tel:${global?.contact_phone?.replace(
                          /[^0-9.]+/g,
                          ''
                        )}`}
                        className="menu--link color--grey-5 whitespace-nowrap">
                        {global?.contact_phone}
                      </a>
                    </p>
                  </div>
                ) : null}
                {global?.contact_email && global?.contact_email_label ? (
                  <div>
                    <p className="color--grey-6 md mb-0">
                      {global?.contact_email_label}
                    </p>
                    <p className="small mb-0">
                      <a
                        href={`mailto:${global?.contact_email}`}
                        className="menu--link color--grey-5">
                        {global?.contact_email}
                      </a>
                    </p>
                  </div>
                ) : null}
              </div>
            </div>
            <nav className="order-1 col-span-5 lg:order-2 lg:pt-16">
              <ul className="nav--large">
                {dropdown_links.map(
                  ({ link_text, link_url, open_in_new_tab }, index) => (
                    <li key={index}>
                      {open_in_new_tab ? (
                        <a
                          href={link_url}
                          target="_blank"
                          rel="noreferrer"
                          onClick={toggleNavModal}>
                          {link_text}
                        </a>
                      ) : (
                        <AniLink
                          cover
                          direction="up"
                          duration={1}
                          bg="color--grey-3"
                          to={link_url}
                          onClick={toggleNavModal}>
                          {link_text}
                        </AniLink>
                      )}
                    </li>
                  )
                )}
              </ul>
            </nav>
            <div className="fade-1 height-margin order-3 col-span-4 pt-6 md:pt-24 lg:col-span-7 lg:hidden">
              <div className="nav--contact flex md:hidden">
                <div>
                  <p className="color--grey-6 mb-0">
                    {global?.contact_phone_label}
                    <br />
                    <a
                      dir="auto"
                      href={`tel:${global?.contact_phone?.replace(
                        /[^0-9.]+/g,
                        ''
                      )}`}
                      className="menu--link color--grey-5">
                      {global?.contact_phone}
                    </a>
                  </p>
                  {global?.contact_email && global?.contact_email_label ? (
                    <>
                      <p className="color--grey-6 mb-0 mt-2 md:mt-6">
                        {global?.contact_email_label}
                        <br />
                        <a
                          href={`mailto:${global?.contact_email}`}
                          className="menu--link color--grey-5">
                          {global?.contact_email}
                        </a>
                      </p>
                    </>
                  ) : null}
                </div>
              </div>
              <p className="caption mb-0 mt-10 md:mt-0" dir="auto">
                &copy;{new Date().getFullYear()}&nbsp;{copyright_text}
              </p>
            </div>
            <div className="fade-1 order-4 col-span-4 hidden pt-24 md:block lg:col-span-5 lg:hidden">
              <ul className="flex justify-end">
                <li className="caption ml-12">
                  {global.terms_of_service_text &&
                    global.terms_of_service_link && (
                      <Link to={global?.terms_of_service_link}>
                        {global?.terms_of_service_link_text}
                      </Link>
                    )}
                </li>
              </ul>
            </div>
          </div>
        </NavDropdown>
        <NavDropdown
          className={classNames(
            'bg--grey-2 max-h-screen overflow-auto pt-20 md:pb-16 md:pt-24 lg:pb-24',
            {
              'is-active': entitiesModalOpen && !isCK,
            }
          )}>
          <div className="container">
            <div className="mt-14 grid grid-cols-4 md:grid-cols-8 lg:grid-cols-12">
              {leftSideOfFormExists ? (
                <div className="col-span-4 md:col-span-3 lg:col-span-4">
                  <div className="h2 fade-up mb-8">
                    <PrismicRichText
                      field={global?.entities_modal_heading.richText}
                    />
                  </div>
                  <div className="mb-8">
                    <PrismicRichText
                      field={global?.entities_modal_paragraph.richText}
                    />
                  </div>

                  <div className="nav--contact mt-12 flex gap-4">
                    {global?.contact_phone && global?.contact_phone_label ? (
                      <div className="mb-8">
                        <p className="color--grey-6 md mb-0">
                          {global?.contact_phone_label}
                        </p>
                        <p className="small">
                          <a
                            dir="auto"
                            href={`tel:${global?.contact_phone?.replace(
                              /[^0-9.]+/g,
                              ''
                            )}`}
                            className="menu--link color--grey-5 whitespace-nowrap">
                            {global?.contact_phone}
                          </a>
                        </p>
                      </div>
                    ) : null}
                    {global?.contact_email && global?.contact_email_label ? (
                      <div>
                        <p className="color--grey-6 md mb-0">
                          {global?.contact_email_label}
                        </p>
                        <p className="small mb-0">
                          <a
                            href={`mailto:${global?.contact_email}`}
                            className="menu--link color--grey-5">
                            {global?.contact_email}
                          </a>
                        </p>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}

              <div
                className={classNames('mb-12', {
                  'col-span-4 md:col-start-5 lg:col-span-5 lg:col-start-8':
                    leftSideOfFormExists,
                  'col-span-full': !leftSideOfFormExists,
                })}>
                {!isCK && <FormResolver color="tertiary" />}
              </div>

              <div className="col-span-4 md:col-span-8 lg:col-span-12">
                <div className="pb-5 md:mt-0 md:pb-0">
                  <hr className="mb-8" />
                  <p className="caption float-right mb-0">
                    {global.terms_of_service_text &&
                      global.terms_of_service_link && (
                        <Link to={global.terms_of_service_link}>
                          {global.terms_of_service_link_text}
                        </Link>
                      )}
                  </p>
                  <p
                    className="caption col-span-3 mb-0 rtl:text-right"
                    dir="auto">
                    &copy;{new Date().getFullYear()}&nbsp;{copyright_text}
                  </p>
                  <div className="clear-both" />
                </div>
              </div>
            </div>
          </div>
        </NavDropdown>
      </div>
      <div className="modal--bg" onClick={toggleNavModal} />
    </>
  );
};

export default Nav;

const ContactLink = ({ global }) => {
  if (global.chat_link && global.chat_link_text) {
    return (
      <a
        href={global.chat_link}
        className="btn--text color--grey-6 whitespace-nowrap md:hidden lg:block">
        {global.chat_link_text}
      </a>
    );
  }
  if (global.contact_phone && global.contact_phone_label) {
    return (
      <a
        dir="auto"
        href={`tel:${global.contact_phone?.replace(/[^0-9.]+/g, '')}`}
        className="btn--text color--grey-6 hidden whitespace-nowrap lg:flex">
        {global.contact_phone}
      </a>
    );
  }
  return null;
};

const TopLinks = ({ topLinks, alignment }) => {
  return (
    <ul
      className={classNames(
        'nav--links col-span-4 hidden md:flex md:justify-center lg:justify-start',
        {
          'me-6': alignment === 'end',
        }
      )}>
      {topLinks.map(({ link_text, link_url, links }, index) => {
        if (!link_text || (!link_url && !links.length)) return null;

        const isPageAnchor = link_url?.[0] === '#';

        if (isPageAnchor) {
          return (
            <li className="relative" key={index}>
              <a
                href={link_url}
                className="btn--text color--grey-5 whitespace-nowrap"
                onClick={(event) => {
                  event.preventDefault();
                  const anchorElement = document.querySelector(link_url);
                  if (anchorElement) {
                    anchorElement.scrollIntoView();
                  }
                }}>
                {link_text}
              </a>
            </li>
          );
        }

        return (
          <li
            className={classNames('relative', {
              'nav--dropdown--trigger': !link_url,
            })}
            key={index}>
            {link_url ? (
              <AniLink
                cover
                to={link_url}
                direction="up"
                duration={1}
                bg="color--grey-3"
                className="btn--text color--grey-5">
                {link_text}
              </AniLink>
            ) : (
              <>
                <a
                  role="button"
                  className="btn--text color--grey-5 whitespace-nowrap">
                  {link_text}
                  <IconCaret />
                </a>
                <div
                  className={classNames(
                    'bg--grey-2 absolute hidden p-5 pb-2 pt-2',
                    {
                      'nav--dropdown': !link_url,
                    }
                  )}>
                  {links.map(
                    ({ link_text, link_url, open_in_new_tab }, index) => (
                      <React.Fragment key={index}>
                        {open_in_new_tab ? (
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="color--grey-6 relative block py-2">
                            {link_text} {IconDropdown}
                          </a>
                        ) : (
                          <AniLink
                            cover
                            direction="up"
                            duration={1}
                            bg="color--grey-3"
                            to={link_url}
                            className="color--grey-6 relative block py-2">
                            {link_text}
                          </AniLink>
                        )}
                        {index + 1 !== links.length && (
                          <hr className="hr--grey-4" />
                        )}
                      </React.Fragment>
                    )
                  )}
                </div>
              </>
            )}
          </li>
        );
      })}
    </ul>
  );
};

const IconCaret = () => (
  <svg
    width="8"
    height="4"
    viewBox="0 0 8 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3.82096 0.0952621L0.48763 3.29382L1.12196 3.90479L3.82096 1.49183L6.51997 3.90479L7.1543 3.29382L3.82096 0.0952621Z"
      fill="color--white"
    />
  </svg>
);

const IconDropdown = (
  <svg
    className="absolute"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.29826 8.34745L15.1077 8.36018L15.1204 15.1696L13.8222 15.1696L13.8731 10.5621L9.02375 15.4114L8.05643 14.4441L12.9058 9.59478L8.29826 9.6457L8.29826 8.34745Z"
      fill="color--grey-5"
    />
  </svg>
);

const NavStyles = styled.nav`
  padding-top: 20px;
  padding-bottom: 20px;
  gap: 0;
  align-items: center;
  height: 68px;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    align-items: center;
  }

  .nav--links {
    transition: all 0.3s ease-in;

    li {
      display: inherit;
      padding: 0 10px 0;
      @media ${media.xlUp} {
        padding: 0 30px 0 0;
      }
    }
  }

  .nav--hide {
    transition: all 0.3s ease-in;
  }

  .nav--cta {
    justify-content: flex-end;

    li:first-child {
      @media ${media.lgUp} {
        padding-inline-end: 30px;
      }
    }
  }

  .nav--icon--menu {
    padding-inline-start: 30px;
  }

  .icon--menu {
    width: 12px;
    display: block;

    .line-1 {
      transform-origin: right center;
      transition: all 0.1s linear;
    }

    .line-2 {
      transform-origin: right bottom;
      transition: all 0.1s linear;
    }

    .line-3 {
      transform-origin: right top;
      transition: all 0.1s linear;
    }

    &:hover {
      .line-2 {
        transform: translate(-6px, 0);
        width: 14px;
      }

      .line-3 {
        transform: translate(-6px, 0);
        width: 14px;
      }
    }
  }

  .is-active {
    .icon--menu {
      .line-1 {
        width: 0;
      }

      .line-2 {
        transform: rotate(45deg) translate(-6px, 2px);
        width: 12px;
      }

      .line-3 {
        transform: rotate(-45deg) translate(-6px, -1px);
        width: 12px;
      }
    }
  }

  p {
    font-size: inherit;
  }

  .nav--dropdown--trigger {
    & > a > svg {
      display: inline-block;
      position: relative;
      top: auto;
      inset-inline-end: auto;
      margin-inline-start: 4px;
      transition: all 0.3s ease;
      transform: rotate(180deg);
    }
  }

  .nav--dropdown--trigger:hover {
    .nav--dropdown {
      display: block;
      height: auto;
      opacity: 1;
    }

    .nav--dropdown--language {
      height: auto;
    }

    & > a > svg {
      transform: rotate(0deg);
    }
  }

  .nav--dropdown {
    display: block;
    overflow: hidden;
    transition: all 0.3s ease;
    height: 0;
    top: 17px;
    width: 210px;
    opacity: 0;

    a {
      font-size: 14px;
      font-weight: normal;
      line-height: 170%;
      letter-spacing: 0.02em;
      text-transform: none;

      &:hover {
        color: #000;

        svg {
          transform: translate(3px, -3px);
        }
      }

      svg {
        transition: all 0.2s ease;
        top: 8px;
        inset-inline-end: 0;
      }
    }

    .color--grey-6 path {
      fill: var(--grey-6);
    }
  }
`;

const NavDropdown = styled.div<{ siteTag: string }>`
  ${({ siteTag }) => {
    if (siteTag === 'site:kitchenbase') {
      return css`
        background-color: var(--primary-light) !important;

        p,
        .nav--contact a,
        .nav--large a {
          color: var(--black) !important;
        }
      `;
    }
  }}

  position: fixed;
  top: 0;
  z-index: 19;
  width: 100%;
  height: 100vh;
  transform: translate(0, -150%);
  transition: all 0.4s cubic-bezier(0.4, 0, 1, 1); // Ease In
  @media ${media.lgUp} {
    height: auto;
  }

  .container {
    height: 90%;
    @media ${media.mdUp} {
      height: 100%;
    }
    @media ${media.lgUp} {
      height: auto;
    }
    @media screen and (${media.mdUp}) and (max-height: 759px) {
      height: auto;
    }
  }

  &.is-active {
    transform: translate(0, 0);
    transition: all 0.5s cubic-bezier(0, 0, 0.2, 1); // Ease Out
  }

  .nav--contact {
    a {
      font-size: 14px;
      color: var(--grey-5);

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  .nav--large {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      margin-bottom: 10px;
      @media ${media.mdUp} {
        margin-bottom: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      display: inline-block;
      padding: 0 0 10px 0;
      position: relative;
      font-size: 34px;
      line-height: 80%;
      letter-spacing: -0.01em;
      color: var(--grey-6);
      overflow: hidden;
      @media ${media.mdUp} {
        font-size: 44px;
      }

      &::before {
        position: absolute;
        bottom: 0;
        inset-inline-start: 0;
        content: '';
        width: 100%;
        height: 2px;
        background: var(--grey-6);
        transition: all 0.15s linear;
        transform: translateX(calc(-100% - 0.1rem));
        animation: 0.25s linear 0s line-out forwards;
      }

      &:hover,
      &:focus {
        &::before {
          animation: 0.25s linear 0s line-in forwards;
        }
      }
    }
  }

  .hide-height {
    display: none;
    @media screen and (${media.mdUp}) and (min-height: 760px) {
      display: block;
    }
  }

  .height-margin {
    @media screen and (${media.mdUp}) and (max-height: 759px) {
      margin-top: 0;
      padding-top: 0;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade-out {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const NavStylesOuter = styled.div<{ siteTag: string }>`
  ${({ siteTag }) => {
    if (siteTag === 'site:kitchenbase') {
      return css`
        background-color: var(--primary-light) !important;

        & .nav--links a {
          color: var(--black);
        }
      `;
    }
    if (siteTag === 'site:profood-solutions') {
      return css`
        background-color: black !important;

        & .nav--links,
        .nav--cta {
          a {
            color: white;
          }
        }

        .icon--menu {
          stroke: var(--white);
          fill: var(--white);
        }

        .nav--icon--menu {
          display: none; // PN: Disable mobile menu for now because profood-solutions only has one page
        }
      `;
    }
  }}
`;
