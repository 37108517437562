import { PrismicText, PrismicRichText } from '@prismicio/react';
import { hasRichText } from '@publicss/utils';
import classNames from 'classnames';
import React, { useState, useRef } from 'react';
import { useLayoutEffect } from 'react';

import {
  Form as FormProps,
  FormFields as FormFieldProps,
  FormBodyFormstep,
  FormBodyFormstepPrimary,
} from '../../../../types/prismic';
import { Button } from '../index';
import { CircleLoader } from './CircleLoader';
import { Fieldset, splitOptions } from './FormField';

interface FormSectionProps
  extends FormBodyFormstepPrimary,
    Omit<FormProps, '_meta' | '__typename'> {
  isActive: boolean;
  onBack?: () => void;
  onNext?: (e: any) => void;
  setActionUrl?: (url?: string) => void;
  skipDefaultHiddenFields?: boolean;
  isLoading: boolean;
  isSubscription?: boolean;
}

export const FormSection: React.FC<FormSectionProps> = ({
  eyebrow,
  title,
  form_title,
  description,
  copy_after_fields,
  fields,
  submit_text,
  action,
  isActive,
  min_height,
  next_text,
  back_text,
  path,
  setActionUrl,
  onBack,
  onNext,
  skipDefaultHiddenFields,
  isLoading,
  isSubscription = false,
}) => {
  const filtredFields = isActive
    ? fields
    : fields?.map((f) => ({ ...f, required: false }));
  const hasActiveAction = setActionUrl && action?.url && isActive;
  const submitRef = useRef(null);

  useLayoutEffect(() => {
    if (hasActiveAction) setActionUrl(action?.url);
    if (path && isActive) history.pushState({}, '', path);
  }, [hasActiveAction, path, isActive]);

  return (
    <section className={isActive ? 'w-full' : 'hidden'}>
      <div
        className={isSubscription ? 'mb-4' : 'mb-xl'}
        style={{ minHeight: min_height || 'auto' }}>
        {hasRichText(eyebrow) && (
          <div className="eyebrow mb-lg">
            <PrismicText field={eyebrow.richText} />
          </div>
        )}
        {hasRichText(form_title) && (
          <PrismicRichText
            field={form_title.richText}
            components={{
              heading1: ({ children }) => <h2 className="h2">{children}</h2>,
              heading2: ({ children }) => <h2 className="h2">{children}</h2>,
              heading3: ({ children }) => <h2 className="h2">{children}</h2>,
            }}
          />
        )}
        {!hasRichText(form_title) && hasRichText(title) && (
          <div className="h3 mb-lg">
            <PrismicRichText
              field={title.richText}
              components={{
                heading1: ({ children }) => <h2 className="h2">{children}</h2>,
                heading2: ({ children }) => <h2 className="h2">{children}</h2>,
                heading3: ({ children }) => <h2 className="h2">{children}</h2>,
              }}
            />
          </div>
        )}
        {hasRichText(description) && (
          <div className="h4 text-grey-10 mb-lg">
            <PrismicRichText field={description.richText} />
          </div>
        )}
        <div
          className={classNames({
            'mt-2xl': [eyebrow, title, description].some(hasRichText),
          })}>
          <Fieldset
            fields={filtredFields as FormFieldProps[]}
            skipDefaultHiddenFields={skipDefaultHiddenFields}
            isSubscription={isSubscription}
          />
        </div>
        {hasRichText(copy_after_fields) && (
          <div className="text-small mt-lg">
            <PrismicRichText field={copy_after_fields.richText} />
          </div>
        )}
      </div>

      <div className="flex place-content-end gap-sm">
        {hasRichText(back_text) && (
          <Button onClick={onBack} emphasis="medium" variant="secondary">
            <PrismicText field={back_text.richText} />
          </Button>
        )}
        {hasRichText(next_text) ? (
          <>
            <Button
              onClick={(e) => {
                if (hasActiveAction) submitRef.current?.click();
                if (onNext) onNext(e);
              }}
              className="sm-down:flex-grow">
              <PrismicText field={next_text.richText} />
            </Button>
            <input type="submit" ref={submitRef} className="hidden" />
          </>
        ) : (
          <Button
            type="submit"
            size="lg"
            className={classNames('sm-down:flex-grow', {
              'pointer-default border-blue-1 pointer-events-none cursor-default ring':
                isLoading,
            })}>
            <PrismicText field={submit_text?.richText} />
            {isLoading && (
              <div className="ml-2 [&>div>svg]:h-5 [&>div>svg]:w-5">
                <CircleLoader />
              </div>
            )}
          </Button>
        )}
      </div>
    </section>
  );
};

interface FormStepsProps extends Omit<FormProps, '_meta'> {
  steps: FormBodyFormstep[];
  setActionUrl: FormSectionProps['setActionUrl'];
}

export const FormSteps: React.FC<FormStepsProps> = ({
  fields,
  submit_text,
  min_height,
  setActionUrl,
  steps,
}) => {
  const [currentStep, setStep] = useState(0);

  const onBack = () => setStep(currentStep > 0 ? currentStep - 1 : 0);

  const onNext = (e) => {
    const form = e.target.closest('form');

    if (form.checkValidity()) {
      const nextStep = currentStep + 1;
      steps[nextStep] && setStep(nextStep);
    } else {
      form.reportValidity();
    }
  };

  return (
    <>
      <Fieldset
        fields={fields as FormFieldProps[]}
        skipDefaultHiddenFields={false}
      />
      {steps?.map(({ fields, primary }, idx) => (
        <FormSection
          skipDefaultHiddenFields={true}
          {...primary}
          min_height={min_height}
          key={idx}
          isActive={idx === currentStep}
          setActionUrl={setActionUrl}
          fields={fields}
          submit_text={submit_text}
          onBack={onBack}
          onNext={onNext}
        />
      ))}
    </>
  );
};

export function filterSteps(slices, formData) {
  return slices?.filter(({ slice_type, primary }) => {
    if (slice_type !== 'formstep') return false;
    if (!primary?.conditions?.trim()) return true;

    return splitOptions(primary.conditions)
      .map((c) => c.split('='))
      .every(([k, v]) =>
        v
          .split('||')
          .map((v) => v.trim())
          .includes(formData[k.trim()])
      );
  });
}
