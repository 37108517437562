exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-author-js": () => import("./../../../src/templates/author.js" /* webpackChunkName: "component---src-templates-author-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-facility-details-js": () => import("./../../../src/templates/facility-details.js" /* webpackChunkName: "component---src-templates-facility-details-js" */),
  "component---src-templates-facility-gallery-js": () => import("./../../../src/templates/facility-gallery.js" /* webpackChunkName: "component---src-templates-facility-gallery-js" */),
  "component---src-templates-locations-js": () => import("./../../../src/templates/locations.js" /* webpackChunkName: "component---src-templates-locations-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-preview-js": () => import("./../../../src/templates/preview.js" /* webpackChunkName: "component---src-templates-preview-js" */),
  "component---src-templates-static-locations-js": () => import("./../../../src/templates/static-locations.js" /* webpackChunkName: "component---src-templates-static-locations-js" */),
  "component---src-templates-web-stories-index-js": () => import("./../../../src/templates/web-stories-index.js" /* webpackChunkName: "component---src-templates-web-stories-index-js" */),
  "component---src-templates-web-story-js": () => import("./../../../src/templates/web-story.js" /* webpackChunkName: "component---src-templates-web-story-js" */)
}

