import { PrismicRichText } from '@prismicio/react';
import { hasRichText } from '@publicss/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import { useEffect, useState } from 'react';

import { linkResolver } from '../../../../prismic-link-resolver';
import { Form as BasicFormProps } from '../../../../types/prismic';
import countries from '../../../constants/countries.json';
import { usePageContext } from '../../../providers/PageContext';
import { FormSection, FormSteps, filterSteps } from './FormSteps';
import { useFormSubmit } from './useFormSubmit';

export type FormProps = BasicFormProps & {
  isSubscription?: boolean;
  isGated?: boolean;
};

interface FormAdditionalProps {
  isGated?: boolean;
}

export const Form: React.FC<FormProps & FormAdditionalProps> = ({
  title,
  form_title,
  description,
  copy_after_fields,
  footer,
  method,
  action,
  fields,
  submit_text,
  success_redirect,
  body,
  min_height,
  isGated = false,
  // cancel_text,
  success_message,
  // error_message,
  isSubscription = false,
}) => {
  const { utm, siteLang } = usePageContext();

  const _fields = fields
    ? [...fields, ...getHiddenFieldsFrom(utm)]
    : [...getHiddenFieldsFrom(utm)];
  const { onSubmit, onUpdate, formData, setActionUrl, actionUrl, isLoading } =
    useFormSubmit({
      action,
      method,
      success_redirect,
    });
  const steps = filterSteps(body, formData);
  const country = siteLang?.split('-')?.[1] ?? siteLang; // Handle countries with one code, e.g. `id`
  const countryName = countries?.[country.toUpperCase()];

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [currentURL, setCurrentURL] = useState('');

  useEffect(() => {
    if (isGated) {
      const url = window.location.href;
      setCurrentURL(url);
    }
  }, []);

  if (isSubmitted) {
    return (
      <PrismicRichText
        field={success_message?.richText}
        components={{
          heading2: ({ children }) => <h2 className="h2 mb-8">{children}</h2>,
          heading3: ({ children }) => <h3 className="h3 mb-6">{children}</h3>,
          heading4: ({ children }) => <h4 className="h4 mb-4">{children}</h4>,
        }}
      />
    );
  }

  return (
    <form
      onSubmit={(event) => {
        onSubmit(event);
        setIsSubmitted(true);
      }}
      onBlur={onUpdate}
      method={method as string}
      action={actionUrl}
      className="grid gap-xl">
      {isEmpty(steps) ? (
        <FormSection
          title={title}
          form_title={form_title}
          isActive={true}
          description={description}
          copy_after_fields={copy_after_fields}
          fields={_fields}
          submit_text={submit_text}
          isLoading={isLoading}
          isSubscription={isSubscription}
        />
      ) : (
        <FormSteps
          min_height={min_height}
          steps={steps}
          setActionUrl={setActionUrl}
          submit_text={submit_text}
          fields={_fields}
        />
      )}
      {countryName && (
        <>
          <input type="hidden" name="Country" value={countryName} />
          <input type="hidden" name="Country__c" value={countryName} />
        </>
      )}
      {isGated && (
        <input type="hidden" name="blog_gated_content__c" value={currentURL} />
      )}
      {hasRichText(footer) && (
        <div className="text-small blue-links [&_a]:underline">
          <PrismicRichText
            linkResolver={linkResolver}
            field={footer.richText}
          />
        </div>
      )}
    </form>
  );
};

export const getHiddenFieldsFrom = (utm: { [key: string]: string }) =>
  utm
    ? Object.entries(utm)
        .map(([name, value]) => ({
          type: 'hidden',
          name,
          value,
        }))
        .filter(({ value }) => value)
    : [];
