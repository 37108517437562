import {
  PhoneInput,
  TextInput,
  BaseInput,
  TextArea,
  Select,
  Checkbox,
  FormControl,
  FormControlGroup,
  RadioButton,
  Stack,
} from '@csscompany/csds-components';
import classNames from 'classnames';
import { compact, isEmpty, omit } from 'lodash';
import React, { useEffect, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import styled from 'styled-components';

import { FormFields as FormFieldProps } from '../../../../types/prismic';
import countries from '../../../constants/countries.json';
import { usePageContext } from '../../../providers/PageContext';

function mapOption(v) {
  const [value, label] = v.split(':');
  return { value: value.trim(), label: (label || value).trim() };
}

export function splitOptions(str) {
  return str
    ? compact<string>(
        str.includes('\n') ? str.split('\n') : str.split(',')
      ).map((v) => v.trim()) || []
    : [];
}

function multiOptToString(v) {
  return Array.from(v).join(',');
}

function multiOptStringToSet(s) {
  return new Set<string>(s.split(','));
}

const countriesOptions = Object.entries(countries).map(([value, label]) => ({
  value,
  label,
}));

export const FormField: React.FC<FormFieldProps> = ({
  type,
  label,
  name,
  options,
  value,
  ...rest
}) => {
  const [hiddenValue, setValue] = useState<any>(undefined);
  const { siteLang, siteTag } = usePageContext();

  const props = {
    name,
    size: 'large',
    placeholder: label,
    defaultValue: value,
    ...rest,
  };

  // Specifically leaving out defaultValue because we only want to do this on first render
  useEffect(() => {
    if (type === 'multicheckbox') {
      if (type === 'multicheckbox' && props.defaultValue) {
        setValue(multiOptStringToSet(props.defaultValue));
      }
    }
  }, [type, setValue]);

  switch (type) {
    case 'text':
      return (
        <TextInput {...props} className="rtl:!pl-2 rtl:!pr-3 rtl:text-right" />
      );

    case 'textarea':
      return (
        <TextArea {...props} className="rtl:!pl-2 rtl:!pr-3 rtl:text-right" />
      );

    case 'country':
      return (
        <>
          <Select
            {...props}
            options={countriesOptions}
            toMenuItem={(o) => o}
            onChange={setValue}
          />
          <input type="hidden" name={name} value={hiddenValue || ''} />
        </>
      );

    case 'select':
      return (
        <>
          <Select
            {...props}
            options={splitOptions(options)}
            toMenuItem={mapOption}
            onChange={setValue}
          />
          <input type="hidden" name={name} value={hiddenValue || ''} />
        </>
      );

    case 'telefone': {
      const country =
        siteTag === 'site:profood-solutions'
          ? ''
          : siteLang?.split('-')?.[1] ?? siteLang ?? 'us'; // Handle countries with one code, e.g. `id`

      const pattern = props.required ? '.{10,}' : undefined;
      return (
        <PhoneInput
          {...omit(props, 'defaultValue')}
          initialCountry={country}
          pattern={pattern}
          onChange={(_value, country) => setValue(country.countryCode)}
        />
      );
    }

    case 'hidden':
      return (
        <input type="hidden" name={name} value={value || ''} required={false} />
      );

    case 'number':
      return (
        <BaseInput
          {...props}
          type="number"
          min={0}
          value={hiddenValue || ''}
          className="rtl:text-right"
          onChange={(e) =>
            setValue(Math.abs(Number(e.target.value)).toString())
          }
        />
      );

    case 'checkbox':
      return (
        <>
          <FormControl
            control={
              <Checkbox
                {...omit(props, 'defaultValue', 'placeholder')}
                checked={
                  hiddenValue === undefined
                    ? props.defaultValue === 'true' ||
                      props.defaultValue === 'checked'
                    : hiddenValue === 'true'
                }
                onChange={() => {
                  setValue((v) => {
                    if (v === '') {
                      if (
                        props.defaultValue === 'true' ||
                        props.defaultValue === 'checked'
                      ) {
                        return 'false';
                      } else {
                        return 'true';
                      }
                    } else {
                      if (v === 'true') {
                        return 'false';
                      }
                      return 'true';
                    }
                  });
                }}
              />
            }>
            {label}
          </FormControl>
          <input
            type="hidden"
            name={name}
            value={(hiddenValue === undefined
              ? props.defaultValue === 'true' ||
                props.defaultValue === 'checked'
              : hiddenValue === 'true'
            ).toString()}
            required={false}
          />
        </>
      );
    case 'radio':
      return (
        <Stack direction="vertical">
          <div className={classNames('text-grey-12 mb-1')}>{label}</div>
          <FormControlGroup>
            {splitOptions(options).map((opt) => (
              <FormControl
                key={opt}
                control={
                  <RadioButton
                    name={name}
                    checked={
                      hiddenValue === undefined
                        ? props.defaultValue === opt
                        : hiddenValue === opt
                    }
                    onChange={() => {
                      setValue(opt);
                    }}
                  />
                }>
                {opt}
              </FormControl>
            ))}
          </FormControlGroup>
          <input
            type="hidden"
            name={name}
            value={hiddenValue === undefined ? props.defaultValue : hiddenValue}
            required={false}
          />
        </Stack>
      );
    case 'multicheckbox':
      return (
        <Stack direction="vertical">
          <div className={classNames('text-grey-12 mb-1')}>{label}</div>
          <FormControlGroup>
            {splitOptions(options).map((opt) => (
              <FormControl
                key={opt}
                control={
                  <Checkbox
                    checked={
                      hiddenValue !== undefined ? hiddenValue.has(opt) : false
                    }
                    onChange={() => {
                      setValue((v) => {
                        const newSet = v === undefined ? new Set() : new Set(v);

                        if (newSet.has(opt)) {
                          newSet.delete(opt);
                        } else {
                          newSet.add(opt);
                        }
                        return newSet;
                      });
                    }}
                  />
                }>
                {opt}
              </FormControl>
            ))}
          </FormControlGroup>
          <input
            type="hidden"
            name={name}
            value={
              hiddenValue !== undefined ? multiOptToString(hiddenValue) : ''
            }
            required={false}
          />
        </Stack>
      );

    default:
      return (
        <BaseInput
          className="rtl:!pl-2 rtl:!pr-3 rtl:text-right"
          {...props}
          type={type as string}
        />
      );
  }
};

interface FieldsetProps {
  legend?: string;
  fields: FormFieldProps[];
  className?: string;
  isSubscription?: boolean;
}

const StyledFieldset = styled.fieldset<{ isRtl: boolean }>`
  *,
  *::placeholder {
    font-size: 1.1rem !important;
    line-height: 1.8rem !important;
  }

  ${(props) =>
    props.isRtl
      ? `
    input[type="tel"] {
      padding-right: 56px!important;
      padding-left: 8px!important;
    }
    .flag-dropdown {
      padding-left: 0!important;
      padding-right: 12px!important;
    }
    [data-testid="csds-select-input-content"] {
      padding-right: 12px!important;
      padding-left: 8px!important;
    }
    [data-testid="csds-select-input"] svg {
      margin-left: 16px;
    }
    `
      : ``}

  .react-tel-input {
    .flag-dropdown {
      display: flex;
      align-items: center;
    }

    .selected-flag .flag {
      margin-top: 0;
      position: static;
    }

    .selected-flag {
      border: 1px solid var(--grey-4);
      padding: 6px 2px;
      height: fit-content;
      margin-top: 0;
    }

    input[type='tel'] {
      padding-left: 56px !important;
    }

    .arrow {
      display: block;
    }
  }
`;

export const Fieldset: React.FC<FieldsetProps> = ({
  legend,
  fields,
  isSubscription = false,
}) => {
  if (isEmpty(fields)) return <></>;
  const hasLegend = legend && !['undefined', 'null'].includes(legend);
  const allHidden = fields.every(({ type }) => type === 'hidden');
  const { isRtl } = usePageContext();

  return allHidden ? (
    <>
      {fields.map((item, idx) => (
        <FormField {...item} key={idx} />
      ))}
    </>
  ) : (
    <StyledFieldset
      isRtl={isRtl}
      className={classNames(
        'grid gap-x-md rtl:text-right',
        isSubscription ? '-mb-4' : '-mb-xl'
      )}>
      {hasLegend && <legend>{legend}</legend>}
      {fields.map((item, idx) => (
        <div
          key={idx}
          className={classNames(
            item.width === 'half' ? 'col-span-1' : 'col-span-2',
            item.type === 'hidden' ? 'mb-0' : isSubscription ? 'mb-4' : 'mb-xl'
          )}>
          <FormField {...item} />
        </div>
      ))}
    </StyledFieldset>
  );
};
