export function getFormData(element) {
  try {
    const form =
      element.tagName.toUpperCase() === 'FORM'
        ? element
        : element.closest('form');
    return Object.fromEntries(new FormData(form));
  } catch (err) {
    console.warn(err.message);
    return {};
  }
}
