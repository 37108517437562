import { getFormData } from '@publicss/utils';
import { navigate } from 'gatsby';
import { debounce } from 'lodash';
import { useState, useCallback } from 'react';

import { relativeLinkResolver } from '../Link';

export function useFormSubmit({ action, method, success_redirect }) {
  const resolvedActionUrl = relativeLinkResolver(action?.url);

  const [didSubmit, setDidSubmit] = useState(false);
  const [submitError, setError] = useState();
  const [formData, setData] = useState({});
  const [actionUrl, setActionUrl] = useState(resolvedActionUrl);
  const [isLoading, setLoading] = useState(false);

  const onSubmit = useCallback(
    (e) => {
      // horible hack for the sake of bookit
      if (actionUrl.includes('/bookit')) return;

      e.preventDefault();
      setLoading(true);
      const body = JSON.stringify(getFormData(e.target));
      const options = { method: method || 'POST', body };

      fetch(resolvedActionUrl, options)
        .then((res) => res.json())
        .then((data) => {
          setDidSubmit(true);

          // // Sync lead data into qualified
          // window?.qualified?.('identify', JSON.parse(body));

          const redirect_url = data?.redirect_url
            ? relativeLinkResolver(data.redirect_url)
            : relativeLinkResolver(success_redirect?.url);
          if (redirect_url !== '/') navigate(redirect_url);
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          console.warn(err.message);
          setError(err.message);
        });
    },
    [actionUrl]
  );

  const onChange = useCallback(
    debounce((e) => {
      const { value, name } = e.target;
      setData({ ...formData, [name]: value });
    }, 100),
    []
  );

  const onUpdate = useCallback(
    debounce((e) => {
      const data = getFormData(e.target.form || e.target);
      if (data) setData(data);
    }, 200),
    []
  );

  return {
    onSubmit,
    onChange,
    onUpdate,
    formData,
    didSubmit,
    actionUrl,
    setActionUrl,
    submitError,
    isLoading,
  };
}
